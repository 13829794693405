/* App.css */

.App-logo {
  height: 20vmin;
  cursor: pointer;
}

.App-header {
  display: flex;
  justify-content: center;
  position:relative;
}


.orbit {
  position: relative;
  width: 550px;
  /* Adjust size as needed */
  height: 550px;
  /* Adjust size as needed */
}

.orbit-logo {
  position: absolute;
  top: 50%;
  left: 30%;
  transform-origin: center center;
  cursor: pointer;

}