
html {
  background-image: url("https://www.pixel4k.com/wp-content/uploads/2018/10/sky-full-of-stars-space-4k_1540139420.jpg.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-color: #333; /* Fallback color in case the image doesn't load */
}

html::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: inherit;
  background-size: inherit;
  background-repeat: inherit;
  background-attachment: inherit;
  background-position: inherit;
  filter: grayscale(100%);
  z-index: -1;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #333; /* Fallback color in case the image doesn't load */
  position: relative; /* Required for the pseudo-element to work */
  z-index: 1; /* Ensure the body content is on top of the pseudo-element */
}

body::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("https://www.pixel4k.com/wp-content/uploads/2018/10/sky-full-of-stars-space-4k_1540139420.jpg.webp");
  background-size: cover;
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center;
  filter: grayscale(100%);
  z-index: -1; /* Ensure the pseudo-element is behind the body content */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div{
  color:white
}

input{
  text-align: center;
}

.header{
  color: white;
  padding: 5px;
  margin: 5px;
  font-size: large;
  font-weight: bold;
  text-align: center;
}
.content{
  background-color: #8f8f8f47;
  color: white;
  border: 2px solid white;
  border-radius: 5px;
  padding: 15px;
  margin: 10px;
  text-align: center;
  font-size: large;
  font-weight: bold;
}


.answerButton{
  background-color: transparent;
  border: 2px solid white;
  color: white;
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  width: 50%;
  
}

.hintButton{
  background-color: transparent;
  border: 2px solid white;
  color: white;
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
 margin-top: 50px;
  cursor: pointer;
  border-radius: 5px;
  width:25%;
  
}

.answers{
  display: block;
  justify-content: center;
  align-items: center;
}

.btn{
  text-align: center;
}

.answerInput{
  text-align: center;
  width: 100%;
  font-size: large;
  font-weight: bold;
  background-color: #8f8f8f47;
  color: white;
}